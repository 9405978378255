<template>
  <div class="container">
    <div class="content">
      <my-icon fontSize="128" iconName="icon-wangluojiance_wancheng_128_lv"></my-icon>
      <div class='fin_style'>{{$t('login.pwdSuccess')}}</div>
      <div class='back_style'>{{$t('login.backIndex')}}
        <span @click='clickLogin'>{{$t('login.nowLogin')}}</span>
      </div>
      <Back :type='type'></Back>
    </div>

  </div>
</template>
<script>
  import Back from "@/views/login/back.vue";
  export default {
    components: {
      Back
    },
    data() {
      return {
        type:'login'
      }
    },
    methods: {
      clickLogin() {
        this.$router.push({
          name: 'login'
        })
      },
    }
  }

</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: relative;

    .content {
      width: 46%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      .fin_style {
        font-size: 32px;
        color: #333333;
        margin: 20px 0 10px 0;
      }

      .back_style {
        font-size: 24px;
        color: #999999;
        margin-bottom: 48px;

        span {
          color: #1AB370;
        }
      }
    }
  }

</style>
